<template>
  <div>
    <!-- header -->
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <header
        class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear"
        :class="
          fixtop ? 'bg-white shadow-md z-10' : ' bg-gradient-to-b from-white'
        "
      >
        <div class="container">
          <div class="flex flex-wrap items-center justify-between">
            <img src="/images/main-logo.png" alt="" class="w-32" />
            <div>
              <p
                class="w-32 py-1 text-center transition duration-300 ease-linear transform rounded-md hover:scale-110 bg-sg-liteblue"
              >
                <a href="tel:+60123943188">Call Us Now</a>
              </p>
            </div>
          </div>
        </div>
      </header>
      <!-- main banner -->
      <div class="py-44 lg:py-52">
        <div class="container">
          <div class="md:w-2/3 lg:w-1/2">
            <!-- left -->
            <div class="p-5 bg-gray-900 bg-opacity-60">
              <h1 class="text-2xl font-semibold text-white">
                Sleek & Discreet Teeth Straightening with Invisalign At
                <span class="text-sg-liteblue">Signature Dental</span>
              </h1>

              <ul
                class="pl-4 mt-6 space-y-2 text-sm text-white list-disc list-outside"
              >
                <li>Transparent pricing - no hidden fees</li>
                <li>Dedicated Invisalign dentist for you</li>
                <li>Digital dental imaging technology</li>
              </ul>

              <div class="mt-5">
                <p class="py-2 text-center text-white rounded-md md:w-60 bg-sg-blue">
                 <a href="https://wa.me/60123943188">Visit Signature Dental Today</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="text-center lg:text-left lg:w-1/2">
            <h1 class="text-2xl font-semibold lg:text-3xl text-ray-700">
              WHAT CAN BE TREATED WITH
              <span class="text-sg-blue">INVISALIGN?</span>
            </h1>
            <p class="pt-4 text-sm leading-tight text-gray-600">
              Invisalign Clear Aligners are able to treat cases ranging from
              minor teeth straightening to moderate and severe cases of crooked
              teeth:
            </p>
          </div>
          <div class="mt-6 lg:w-1/2 lg:mt-0">
            <img src="/images/bg-invisalign-1.jpg" alt="" class="mx-auto md:w-2/3 lg:w-full" />
          </div>
        </div>

        <!-- list invisalign -->
        <div class="md:flex md:flex-wrap md:pt-5 lg:max-w-4xl lg:mx-auto">
        <div v-for="(item, i) in list_teeth" :key="i" class="pb-6 md:w-1/3">
          <div
            class="w-40 p-5 mx-auto border-4 rounded-full border-sg-blue bg-gray-50"
          >
            <img :src="'/images/' + item.image + '.png'" :alt="item.alt" />
          </div>
          <div class="mt-4 text-center">
            <p class="text-xl font-semibold text-gray-600">{{ item.alt }}</p>
            <p class="text-sm text-gray-600">{{ item.p }}</p>
          </div>
        </div>
      </div>
      </div>
    </div>

    <!-- journey -->
    <div class="py-10 bg-blue-50">
      <div class="container">
        <h1 class="text-xl font-semibold leading-tight text-center md:text-2xl md:text-left">
          Your <span class="text-sg-blue">Invisalign Journey</span> At Signature
          Dental For <br class="hidden lg:block"> Sleek & Discreet Teeth Straightening
        </h1>
        <div class="mt-6">
          <p class="font-semibold md:text-xl">Stage 1: Pre-Treatment Consultation</p>
          <p class="mt-2 text-sm leading-tight text-gray-600">
            During your initial treatment consultation, you will be advised if
            any pre-treatment preparations are required. This could include
            extraction of teeth, to create space for teeth movement
          </p>

          <p class="mt-5 font-semibold md:text-xl">Stage 2: Invisalign Treatment</p>
          <p class="mt-2 text-sm leading-tight text-gray-600">
            Once all preparations have been made, your Invisalign treatment can
            begin. Depending on tooth movements required, tiny tooth-coloured
            nodes may be attached to some teeth to assist your aligners in their
            movement.
          </p>
          <ul
            class="pl-5 mt-4 space-y-3 text-sm leading-tight text-gray-600 list-disc list-outside md:pl-8"
          >
            <li>You will be provided with your first set of aligners.</li>
            <li>
              Patients usually fully adjust to their new Invisalign clear
              aligners very quickly, experiencing minimal discomfort.
            </li>
            <li>
              To achieve successful results, you must wear your aligners at
              least 22 hours a day, only removing them when eating or cleaning
              your teeth.
            </li>
            <li>
              Your dentist will review your progress every 4 to 8 weeks, and
              provide you with your next few sets of aligners.
            </li>
            <li>
              Average treatment can last between 9 and 15 months, and an average
              of 18 to 30 aligners. However, treatment time and number of
              aligners will depend on the complexity of your condition, your
              consistency of wear and your ability to maintain good oral health.
            </li>
          </ul>

          <p class="mt-5 font-semibold md:text-xl">Stage 3: Post-Treatment Care</p>
          <p class="mt-2 text-sm leading-tight text-gray-600">
            After completing your Invisalign treatment, any tooth coloured
            nodules initially placed on your teeth will be removed, and your
            teeth and gums will be thoroughly cleaned.
          </p>
          <p class="mt-2 text-sm leading-tight text-gray-600">
            You will likely observe a noticeable, positive change to your smile
            and face. To sustain the results, you will be provided retainers and
            instructions on their wear.
          </p>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-ray-700">
          Why Choose Signature Dental as
          <span class="text-sg-blue">Your Invisalign Provider?</span>
        </h1>

        <div class="mt-7 md:flex md:flex-wrap">
          <div v-for="(item, i) in provider" :key="i" class="pb-6 md:p-2 md:w-1/2 lg:w-1/3">
            <div class="p-6 bg-white rounded-md shadow-lg">
              <img
                :src="'/images/' + item.image + '.png'"
                :alt="item.alt"
                class="w-24 mx-auto"
              />
              <div class="pt-4 text-center">
                <p class="font-semibold">{{ item.alt }}</p>
                <p class="mt-4 text-sm leading-tight text-gray-600 md:h-20 lg:h-28">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- youtube link -->
        <div class="pt-4 lg:max-w-3xl lg:mx-auto">
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/I9beJFiXkAg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <!-- services type -->
    <div class="py-10 bg-opacity-20 bg-sg-blue">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-ray-700">
          Our
          <span class="text-sg-darkblue">Services</span>
        </h1>

        <div class="flex flex-wrap mt-8">
          <div v-for="(item, i) in services" :key="i" class="w-1/2 p-1 md:w-1/3 lg:w-1/4">
            <div
              class="p-6 transition duration-200 ease-linear transform bg-white rounded-md hover:shadow-md hover:scale-110"
            >
              <img
                :src="'/images/' + item.image + '.png'"
                :alt="item.alt"
                class="w-20 mx-auto"
              />
              <div class="h-10 mt-4 text-center">
                <p class="font-medium leading-tight text-gray-600">
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-ray-700">
          Why
          <span class="text-sg-darkblue">Choose Us</span>
        </h1>
        <div class="flex flex-wrap pt-6 md:justify-center">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 p-2 text-center md:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
            <h1 class="pt-4 text-sm leading-tight">{{ item.alt }}</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- location -->
    <div
      id="showroom"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-location.jpg')"
    >
      <div class="container py-20">
        <div class="px-4 py-10 md:w-2/3 lg:w-5/6 md:mx-auto bg-sg-blue">
          <h1
            class="text-2xl font-semibold text-center text-white text-ray-700"
          >
            Visit Our Clinic Now
          </h1>
          <div class="lg:flex lg:mt-6">
            <div
              class="py-6 text-white border-b border-gray-200 lg:px-4 lg:border-b-0 lg:border-r lg:w-1/3"
            >
              <div class="w-full pl-3 text-sm">
                <p class="font-semibold leading-tight uppercase">
                  Signature Dental Clinic (Kota Damansara)
                </p>
                <div class="pt-2 text-sm leading-tight">
                  <p>
                    No.1, Ground Floor, Jalan PJU 5/10, Dataran Sunway, Kota
                    Damansara, 47810 Petaling Jaya, Selangor, Malaysia
                  </p>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">Consultation Hours:</p>
                  <p class="pt-1 text-xs">Mon-Fri: 9:00am-6:30pm</p>
                  <p class="pt-1 text-xs">Sat-Sun: 9:00am-5:00pm</p>
                  <p class="pt-1 text-xs">Closed On Selected Public Holidays</p>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">
                    Phone: <a href="tel:+60123943188">+60 12-394 3188</a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="py-6 text-white border-b border-gray-200 lg:px-4 lg:border-b-0 lg:border-r lg:w-1/3"
            >
              <div class="w-full pl-3 text-sm">
                <p class="font-semibold leading-tight uppercase">
                  Signature Dental Clinic (Uptown)
                </p>
                <div class="pt-2 text-sm leading-tight">
                  <p>
                    52, S21/35, Damansara Utama, 47400 Petaling Jaya, Selangor
                  </p>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">Consultation Hours:</p>
                  <p class="pt-1 text-xs">Mon-Fri: 9:00am-6:30pm</p>
                  <p class="pt-1 text-xs">Sat-Sun: 9:00am-5:00pm</p>
                  <p class="pt-1 text-xs">Closed On Selected Public Holidays</p>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">
                    Phone: <a href="tel:+60123943188">+6012-394 3188</a>
                  </p>
                </div>
              </div>
            </div>

            <div class="flex py-5 text-white lg:px-4 lg:w-1/3">
              <div class="w-full pl-3 text-sm">
                <p class="font-semibold leading-tight uppercase">
                  Signature Dental Clinic (Taman Megah)
                </p>
                <div class="pt-2 text-sm leading-tight">
                  <p>
                    80 & 82, Ground Floor, Jalan SS 24/2, Taman Megah, 47301
                    Petaling Jaya, Selangor
                  </p>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">Consultation Hours:</p>
                  <p class="pt-1 text-xs">Mon-Fri: 9:00am-6:30pm</p>
                  <p class="pt-1 text-xs">Sat-Sun: 9:00am-5:00pm</p>
                  <p class="pt-1 text-xs">Closed On Selected Public Holidays</p>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">
                    Phone: <a href="tel:+60178233180">+6017-823 3180</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Testimonials />

    <!-- enquire form  -->
    <div class="pt-10 bg-sg-liteblue lg:flex lg:pt-0">
      <div class="container lg:py-10 lg:w-1/2">
        <div id="element" class="pb-10">
          <h1 class="pb-4 text-2xl font-medium text-center text-white">
            Make An Appointment
          </h1>
          <EnquiryForm class="lg:max-w-3xl lg:mx-auto" />
        </div>
      </div>
      <div
        class="bg-center bg-no-repeat bg-cover h-60 lg:w-1/2 lg:h-auto"
        style="background-image: url('/images/bg-enquiry.jpg')"
      >
        <!-- <img src="/images/bg-enquiry.jpg" alt=""> -->
      </div>
    </div>

    <footer class="py-10">
      <div class="container">
        <img src="/images/main-logo.png" alt="" class="w-4/5 mx-auto md:w-56 lg:w-96" />
        <div class="mt-5 lg:flex">
          <div class="text-center lg:w-1/2">
            <h1 class="text-lg font-semibold leading-tight text-sg-blue">
              Signature Dental Clinic (Kota Damansara)
            </h1>
            <h1 class="pt-2 leading-tight text-gray-700">
              No.1, Ground Floor, Jalan PJU 5/10, Dataran Sunway, Kota
              Damansara, 47810 Petaling Jaya, Selangor, Malaysia.
            </h1>
            <div class="pt-4">
              <h1>Consultation Hours:</h1>
              <p class="text-sm text-gray-600">
                Mon-Fri: 9.00am - 6.30pm <br />
                Sat-Sun: 9.00am - 6.00pm <br />
                Closed On Selected Public Holidays
              </p>
              <h1 class="pt-3 text-gray-600">
                Call Us At
                <a
                  href="tel:+60123943188"
                  class="transition duration-300 ease-linear hover:text-ting-mediumgreen"
                >
                  +6012-394 3188</a
                >
              </h1>
            </div>
          </div>
          <div class="pt-4 text-center lg:pt-0 lg:w-1/2">
            <h1 class="text-lg font-semibold leading-tight text-sg-blue">
              Signature Dental Clinic (Uptown)
            </h1>
            <h1 class="pt-2 leading-tight text-gray-700">
              52, S21/35, Damansara Utama, 47400 Petaling Jaya, Selangor
            </h1>
            <div class="pt-4">
              <h1>Consultation Hours:</h1>
              <p class="text-sm text-gray-600">
                Mon-Fri: 9.00am - 6.30pm <br />
                Sat-Sun: 9.00am - 5.00pm <br />
                Closed On Selected Public Holidays
              </p>
              <h1 class="pt-3 text-gray-600">
                Call Us At
                <a
                  href="tel:+60123943188"
                  class="transition duration-300 ease-linear hover:text-ting-mediumgreen"
                >
                +6012-394 3188</a
                >
              </h1>
            </div>
          </div>
          <div class="pt-4 text-center lg:pt-0 lg:w-1/2">
            <h1 class="text-lg font-semibold leading-tight text-sg-blue">
              Signature Dental Clinic (Taman Megah)
            </h1>
            <h1 class="pt-2 leading-tight text-gray-700">
              80 & 82, Ground Floor, Jalan SS 24/2, Taman Megah, 47301 Petaling Jaya, Selangor
            </h1>
            <div class="pt-4">
              <h1>Consultation Hours:</h1>
              <p class="text-sm text-gray-600">
                Mon-Fri: 9.00am - 6.30pm <br />
                Sat-Sun: 9.00am - 5.00pm <br />
                Closed On Selected Public Holidays
              </p>
              <h1 class="pt-3 text-gray-600">
                Call Us At
                <a
                  href="tel:+60178233180"
                  class="transition duration-300 ease-linear hover:text-ting-mediumgreen"
                >
                +6017-823 3180</a
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!-- Copyright -->
    <div class="py-3 bg-sg-darkblue">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import Testimonials from "@/components/Testimonials.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    carousel,
    EnquiryForm,
    Testimonials,
  },
  data() {
    return {
      fixtop: false,
      choose: [
        {
          image: "/images/choose-1.png",
          alt: "Professional and certified dentists with 18 years of experience",
        },
        {
          image: "/images/choose-2.png",
          alt: "High quality treatment and services",
        },
        {
          image: "/images/choose-3.png",
          alt: "Cosy and comfortable environment",
        },
        { image: "/images/choose-5.png", alt: "Cutting-edge technology" },
        {
          image: "/images/choose-6.png",
          alt: "Conveniently located at Kota Damansara, Damansara Uptown, Taman Megah, Petaling Jaya",
        },
        { image: "/images/choose-7.png", alt: "Operating 7 days a week" },
        // { image: "/images/choose-4.png", alt: "Personalised dental care" },
      ],
      testimonial: [
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 1",
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 2",
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 3",
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 4",
        },
      ],
      invisalign: [
        { image: "/images/invisalign-1", alt: "SPACING" },
        { image: "/images/invisalign-2", alt: "OVERJET" },
        { image: "/images/invisalign-3", alt: "CROSSBITE" },
        { image: "/images/invisalign-4", alt: "OPEN BITE" },
        { image: "/images/invisalign-5", alt: "CROWNDING" },
        { image: "/images/invisalign-6", alt: "UNDERBITE" },
      ],
      choose_invisalign: [
        "Virtually Invisible",
        "More comfortable",
        "You can eat whatever you want",
        "Shorter treatment duration",
        "Lesser dental visits",
      ],

      list_teeth: [
        {
          image: "teeth-1",
          alt: "GAPPED TEETH",
          p: "Gaps between the teeth",
        },
        {
          image: "teeth-2",
          alt: "CROSSBITE",
          p: "Upper and lower jaws are misaligned",
        },
        {
          image: "teeth-3",
          alt: "OVERCROWDING",
          p: "Not enough room for the teeth",
        },
        {
          image: "teeth-4",
          alt: "OPENBITE ",
          p: "Vertical spacing between the front teeth",
        },
        {
          image: "teeth-5",
          alt: "UNDERBITE",
          p: "Protruding lower teeth",
        },
        {
          image: "teeth-6",
          alt: "OVERBITE",
          p: "Protruding upper teeth",
        },
      ],
      provider: [
        {
          image: "provider-1",
          alt: "Transparent Pricing, No Hidden Fees",
          p: "After the initial consultation and assessment by our friendly dentists, the full and transparent pricing will be presented and explained. There are no hidden fees",
        },
        {
          image: "provider-2",
          alt: "Dedicated Invisalign Dentist for You",
          p: "When you start your Invisalign journey at Signature Dental, you can expect to consult the same friendly dentist throughout your follow-up appointments and even maintenance.",
        },
        {
          image: "provider-3",
          alt: "Digital Dental Imaging Technology",
          p: "We combine our dentists’ experience and expertise with digital dental imaging technology for enhanced precision, safety and quality. Your smile is our pride.",
        },
      ],
      services: [
        { image: "service-1", alt: "Clear Aligners" },
        { image: "service-2", alt: "Veneer" },
        { image: "service-3", alt: "Oral Care for Children" },
        { image: "service-4", alt: "Scaling and Polishing" },
        { image: "service-5", alt: "Dental Fillings" },
        { image: "service-6", alt: "Teeth Whitening" },
        { image: "service-7", alt: "Braces" },
        { image: "service-8", alt: "Root Canal Treatment" },
        { image: "service-9", alt: "Crowns and Bridges" },
        { image: "service-10", alt: "Dental Implant" },
        { image: "service-11", alt: "Denture" },
        { image: "service-12", alt: "Wisdom Teeth Removal" },
      ],
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
