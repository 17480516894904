<template>
  <div>
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-ray-700">
          Our
          <span class="text-sg-darkblue">Testimonials</span>
        </h1>

        <div class="mt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              767: { items: 2, nav: false, dots: true },
              1000: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="relative p-5 rounded-md bg-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-10 h-10 text-red-600 fill-current"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
                  />
                </svg>
                <div class="pt-3">
                  <p class="h-40 text-sm text-gray-600">
                    {{ item.p }}
                  </p>
                </div>
                <div class="pt-4">
                  <p class="text-base font-semibold text-red-900">
                    {{ item.client }}
                  </p>
                  <div class="text-yellow-400">
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="absolute w-20 bottom-2 right-4">
                  <img src="/images/google-review.png" alt="" class="" />
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="hidden">
          <img src="/images/bg-testimonial.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "We think The Signature Dental Clinic is the best. They are fantastic with all our . we have two daughters with developmental delays. The staff are so patient and Dr Cham, Dr Choong so good with them. they actually get excited to see the dentist.",
          client: "Mus'ab Yamani",
        },
        {
          p: "Dr Cham is extremely talented! He and the staff took the time to really understand my issues and fixed both my problems effectively with the least invasive of the treatment options available. Thank y'all for all you do.",
          client: "Leong Jia Le",
        },
        {
          p: "Great service! They are always very willing and flexible with patients as well. I just got wisdom teeth removed and they made the process so easy",
          client: "Silvia",
        },
        {
          p: "The doctor is very gentle, tender and sensitive to patient's needs. Very good cleaning experience. Not stressful. Nice ambience. Gives very good advice. Will come back again. No problems with parking.",
          client: "Shook Hwa Then",
        },
        {
          p: "High recommended this dental clinic 👍🏻👍🏻 Very professional dentist and lovely nurse. I really like their environment, feel very comfortable",
          client: "Chong Grace",
        },
        {
          p: "I have been to several dentists for checkups and this is by far the best one. Dentist was friendly, thorough with her work and very informative in order for patients to make accurate decisions for their teeth. This is highly recommended, 10/10.",
          client: "Christopher Chung ",
        },
        {
          p:'Went for scaling and polishing. Spacious and clean. Doctor was professional and patient.',
          client:'Sher Maine'
        },
        {
          p:'Comfy & clean. Both Dentist & assistant are patient, professional and friendly. Recommended ',
          client:'Mei Leng Kong'
        }
        
      ],
    };
  },
};
</script>
