<template>
  <div
    class="bg-center bg-no-repeat bg-cover md:bg-top"
    style="background-image: url('/images/bg-main.jpg')"
  >
    <div class="container pt-32 pb-10 md:pt-40 md:pb-20 lg:pt-52 lg:pb-40">
      <div class="md:w-4/5 lg:w-1/2">
        <h1 class="text-2xl leading-tight text-gray-800 lg:text-4xl">
          Specialist-driven Invisalign Treatment at
          <span class="font-semibold text-sg-darkblue"
            >Signature Dental Clinic.</span
          >
        </h1>
        <div class="bg-ting-liteblue h-0.5 w-24 my-5"></div>
        <div v-if="!hideEnquiry" class="flex flex-wrap">
          <h1
            class="w-32 p-2 m-1 text-center text-white transition duration-150 ease-linear rounded-lg bg-sg-blue hover:bg-sg-darkgreen"
          >
            <a href="https://wa.me/60123943188">Whatsapp Us</a>
          </h1>
          <h1
            class="w-32 p-2 m-1 text-center text-white transition duration-150 ease-linear rounded-lg bg-sg-blue hover:bg-sg-darkgreen"
          >
            <a href="https://goo.gl/maps/bfqtXdFnz9oMpDbJ8">Locate Us</a>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
